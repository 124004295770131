import { useNavigate } from "react-router-dom";
import { Typography, Paper, Grid } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const PDFTemplateContent = ({ categoriesData, isTexasCombined, texasURL }) => {
  const navigate = useNavigate();
  return categoriesData !== undefined &&
    categoriesData?.filter((item) => item?.is_texas === 1 || item?.is_pdf_template === 1)?.filter((e) => isTexasCombined || e?.is_texas !== 1)?.length > 0 ? (
    <>
      <Typography className="pdf_tempate_title" sx={{ mt: "82px !important", mb: "41px !important" }}>
        ADDITIONAL INSPECTIONS
      </Typography>
      <div>
        {categoriesData?.filter((item) => item?.is_texas === 1 || item?.is_pdf_template === 1)?.filter((e) => isTexasCombined || e?.is_texas !== 1)?.map((value, index) => (
          <Paper
            className="pdf-template-paper"
            sx={{ mb: 3, cursor: "pointer" }}
            id={value?.name}
            onClick={() =>
              navigate("/pdf", {
                state: { name: value?.is_texas === 1 ? texasURL : value?.pdf_report },
              })
            }
          >
            <Grid container sx={{ p: "18px", gap: "5px", pt: "20px" }}>
              <Grid item xs={0.8} sx={{ pt: value?.description ? 1 : 0 }}>
                <ContentPasteIcon className="content_paste_icon" />
              </Grid>
              <Grid item xs={11}>
                <p style={{ fontSize: "16px", lineHeight: "25px", fontWeight: "700", letterSpacing: "0.25px", color: "#3b3b3b" }}>{value?.name}</p>
                <p style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400", letterSpacing: "0.25px", color: "#3b3b3b" }}>{value?.description}</p>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </div>
    </>
  ) : (
    ""
  );
};

export default PDFTemplateContent;
